import React, { useState } from 'react'

import { tr } from 'mmfintech-commons'

import settings from '../../settings'

import ClientFaqIcon from '../../assets/icons/client_faq_icon.svg'
import ContactSupportIcon from '../../assets/icons/contact_support_icon.svg'

import { SupportPaneWrapper } from './SupportBlock.styled'

const extraSupportConst = [
  {
    Icon: ClientFaqIcon,
    title: (() => tr('FRONTEND.DEPOSIT.LIVE_CHAT_SUPPORT', 'Client FAQ'))(),
    path: settings.FAQUrl,
    enableChatOpen: false
  },
  {
    Icon: ContactSupportIcon,
    title: (() => tr('FRONTEND.DEPOSIT.LIVE_CHAT_SUPPORT', 'Contact support'))(),
    path: settings.customerServiceUrl,
    enableChatOpen: false
  }
]

export const SupportBlock = () => {
  const [isOpenChat, setIsOpenChat] = useState<boolean>(false)

  const openChat = () => {
    const windowObj = window as any
    if (windowObj?.zE) {
      setIsOpenChat(!isOpenChat)
      isOpenChat ? windowObj?.zE('messenger', 'open') : windowObj?.zE('messenger', 'close')
    }
  }
  return (
    <SupportPaneWrapper>
      <div className='support-panel-right-side-title'>
        {tr('FRONTEND.DEPOSIT.EXTRA_SUPPORT_LABEL', 'Need extra support?')}
      </div>
      {extraSupportConst.map(({ Icon, path, title, enableChatOpen }, index) => {
        return enableChatOpen ? (
          <React.Fragment key={index}>
            <div
              onClick={() => enableChatOpen && openChat()}
              className='support-panel-right-side-support-wrapper'
              key={title}>
              <div className='support-panel-icon'>
                <img src={Icon} alt={title} />
              </div>
              {/* <CoreButton collapsed CollapsedIcon={Icon} variation='elevated' size='large' /> */}
              <div>{title}</div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <a href={path} className='support-panel-right-side-support-wrapper' key={title}>
              <div className='support-panel-icon'>
                <img src={Icon} alt={title} />
              </div>
              {/* <CoreButton collapsed CollapsedIcon={Icon} variation='elevated' size='large' /> */}
              <div>{title}</div>
            </a>
          </React.Fragment>
        )
      })}
    </SupportPaneWrapper>
  )
}

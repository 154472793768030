import cn from 'classnames'

import { CountryIconWrapper } from './CountryIcon.styled'
import { configuration } from 'mmfintech-checkout-commons'
import { getCountryImageUrl } from 'mmfintech-commons'

type CurrencyIconProps = {
  countryCode: string
  size?: string
  circle?: boolean
  borderless?: boolean
  className?: string
}

export const CountryIcon = ({
  countryCode,
  size = '3rem',
  circle = false,
  borderless = false,
  className = ''
}: CurrencyIconProps) => (
  <CountryIconWrapper
    size={size}
    className={cn(className, {
      circle,
      borderless,
      [countryCode?.replace(/\./g, '-')]: true
    })}>
    <img src={`${configuration.readBackendConfig()}${getCountryImageUrl(countryCode)}`} alt='' />
  </CountryIconWrapper>
)

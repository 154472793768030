// @use '@scss/typography' as *;

import styled from 'styled-components'

export const PaymentMethodsWrapper = styled.div`
  height: 15.7rem;
  overflow-x: hidden;
  scrollbar-width: none;
  transition: all 0.4s ease;
  width: 100%;
  gap: 1.6rem;
  position: relative;
  background-color: #ffffff;
  border-radius: 2.4rem;
  .chevron {
    position: absolute;
    top: 20px;
    right: 26px;
    cursor: pointer;
    transform: rotate(180deg);
    transition: all 0.3s ease;

    &.collapsed {
      transform: rotate(0deg);
    }
  }
  .payment-method-header {
    padding: 1.6rem;
    border-bottom: 1px solid rgba(231, 236, 239, 1);
    user-select: none;
    .payment-method-title {
      opacity: 0.5;
      color: rgba(2, 6, 23, 1);
      font-size: 16px;
      font-weight: 600;
      line-height: 20.8px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
  .payment-method-container {
    display: flex;
    gap: 1.6rem;
    flex-direction: column;
    padding: 1.6rem;
    .payment-method-wrapper {
      width: 100%;
      padding: 1.6rem;
      background-color: rgba(247, 248, 250, 1);
      border-radius: 1.6rem;
      display: flex;
      gap: 1.6rem;
      height: 7.2rem;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &:hover {
        border: 1px solid #ef4444;
        background-color: rgba(239, 68, 68, 0.1);
      }

      &.selected {
        background-color: rgba(239, 68, 68, 0.1);
        position: relative;
        border: 1px solid #ef4444;
        pointer-events: none;
        opacity: 1;

        &::after {
          content: '';
          position: absolute;
          top: 1.9rem;
          right: 2.4rem;
          width: 6px;
          height: 10px;
          border-bottom: 2px solid red;
          border-right: 2px solid red;
          border-bottom-right-radius: 3px;
          transform: rotate(48deg);
          z-index: 1;
        }

        &::before {
          content: '';
          position: absolute;
          top: 1.5rem;
          right: 1.6rem;
          width: 2.4rem;
          height: 2.4rem;
          background-color: white;
          border-radius: 50%;
          z-index: 1;
        }
      }

      .payment-method-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 4rem;
        min-height: 4rem;
        background-color: #ef4444;
        border-radius: 1.2rem;
        overflow: hidden;

        img {
          width: 4rem;
          height: 4rem;
          object-fit: fill;
        }
      }

      .payment-method-content {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        width: calc(100% - 6.4rem);
        user-select: none;

        .payment-method-title-wrapper {
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          width: 88%;

          span {
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.08rem;
            transition: transform 1500ms ease;
          }
        }

        .subtitle {
          opacity: 0.5;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.68rem;
          white-space: nowrap;
        }
      }
    }
  }
`

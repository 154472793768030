import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { getPaymentMethodImageUrl, isValidArray, tr } from 'mmfintech-commons'
import { PaymentOptionResponse, PaymentOptionStatusEnum } from 'mmfintech-commons-types'

import { configuration } from 'mmfintech-checkout-commons'

import { PaymentMethodsWrapper } from './PaymentMethods.style'

import bankwireImage from '../../assets/methods/bank-transfer.svg'
import creditCardImage from '../../assets/methods/debit-card-icon.svg'
import instantBankTransferImage from '../../assets/methods/bankwire-icon.svg'
import chevronUp from '../../assets/icons/chevron-up.svg'

type TMorePaymentOptions = {
  description?: string
  title?: string
  dataTest?: string
  path?: string
  isDisabled?: boolean
}

interface IPaymentMethodProps {
  paymentOptions: (PaymentOptionResponse & TMorePaymentOptions)[]
  setSelectedPaymentOption?: (value: string, path?: string) => void
  paymentMethod?: string
  disabled?: boolean
  setCollapse?: Dispatch<SetStateAction<boolean>>
  ignoreStatus?: boolean
}

export const PaymentMethods = ({
  paymentOptions,
  setSelectedPaymentOption,
  paymentMethod,
  disabled = false,
  ignoreStatus = false
}: IPaymentMethodProps) => {
  const [filteredOptions, setFilteredOptions] =
    useState<(PaymentOptionResponse & TMorePaymentOptions)[]>(paymentOptions)
  const paymentMethodsContainer = useRef(null)
  const [collapsed, setCollapsed] = useState<boolean>(true)

  useEffect(() => {
    if (isValidArray(paymentOptions) && paymentMethod && paymentMethod) {
      setTimeout(() => {
        setFilteredOptions(paymentOptions.filter(option => option.paymentMethod !== paymentMethod))
      }, 300)
    } else {
      setFilteredOptions(paymentOptions)
    }
  }, [paymentMethod, paymentOptions])

  useEffect(() => {
    if (!collapsed && paymentMethodsContainer.current) {
      paymentMethodsContainer.current.scroll(0, 0)
    }
  }, [collapsed])

  const paymentOptionIcon = (paymentMethod: string): ReactNode => {
    switch (paymentMethod) {
      case 'EMONEY':
      case 'BANKWIRE':
      case 'LOCAL_BANKWIRE':
        return <img src={bankwireImage} alt='' className='local' />

      case 'COMMUNITY_INSTANT_BANK_TRANSFER':
      case 'DIRECT_INSTANT_BANK_TRANSFER':
      case 'INSTANT_BANK_TRANSFER':
        return <img src={instantBankTransferImage} alt='' className='local' />

      case 'CREDIT_CARD':
        return <img src={creditCardImage} alt='' className='local' />

      default:
        return <img src={`${configuration.readBackendConfig()}${getPaymentMethodImageUrl(paymentMethod)}`} alt='' />
    }
  }

  const hoverAttributes = () => {
    return {
      onMouseOver: event => {
        const target = event.target as HTMLSpanElement
        event.stopPropagation()
        if (target.clientWidth > target.parentElement.clientWidth) {
          target.style.transform = `translateX(${-(target.clientWidth - target.parentElement.clientWidth + 24)}px)`
        }
      },
      onMouseLeave: event => {
        const target = event.target as HTMLSpanElement
        target.style.transform = `translateX(0px)`
      }
    }
  }

  return (
    <PaymentMethodsWrapper
      ref={paymentMethodsContainer}
      style={{
        width: '100%',
        height: collapsed ? `${8.8 * paymentOptions.length + 7}rem` : '',
        overflow: !collapsed ? `hidden` : 'auto'
      }}>
      <div className='payment-method-header' onClick={() => setCollapsed(!collapsed)}>
        <span className='payment-method-title'>{tr('CHECKOUT.PAYMENT_METHOD.HEADER_TITLE', 'Select method')}</span>
      </div>
      <div onClick={() => setCollapsed(!collapsed)} className={`chevron ${collapsed ? 'collapsed' : ''}`}>
        <img src={chevronUp} alt='' />
      </div>
      <div className='payment-method-container'>
        {paymentMethod && (
          <div className={cn('payment-method-wrapper full-width selected')} onClick={() => setCollapsed(true)}>
            <div className='payment-method-icon-wrapper'>{paymentOptionIcon(paymentMethod)}</div>
            <div className='payment-method-content'>
              <div className='payment-method-title-wrapper'>
                <span {...hoverAttributes()}>{tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}</span>
              </div>
              {/* {feePercent !== undefined && (
              <span className='subtitle'>{`${tr('FRONTEND.PAYMENT_OPTIONS.FEE_LABEL', 'Fee')} ${feePercent} %`}</span>
            )} */}
            </div>
          </div>
        )}
        {isValidArray(filteredOptions) &&
          filteredOptions.map(method => {
            const { paymentMethod, feePercent, description, title, dataTest, path, isDisabled, status } = method || {}
            return (
              <div
                key={paymentMethod}
                onClick={() => {
                  setSelectedPaymentOption(paymentMethod, path)
                  setCollapsed(false)
                }}
                date-test={dataTest}
                className={cn('payment-method-wrapper', {
                  'full-width': filteredOptions.length === 1,
                  selected: !paymentMethod && paymentMethod === paymentMethod,
                  disabled: disabled || isDisabled || (!ignoreStatus && status !== PaymentOptionStatusEnum.AVAILABLE)
                })}>
                <div className='payment-method-icon-wrapper'>{paymentOptionIcon(paymentMethod)}</div>
                <div className='payment-method-content'>
                  <div className='payment-method-title-wrapper'>
                    <span {...hoverAttributes()}>
                      {title || tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
                    </span>
                  </div>

                  {description && <span className='subtitle'>{description}</span>}

                  {feePercent && (
                    <span className='subtitle'>{`${tr(
                      'FRONTEND.PAYMENT_OPTIONS.FEE_LABEL',
                      'Fee'
                    )} ${feePercent} %`}</span>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </PaymentMethodsWrapper>
  )
}

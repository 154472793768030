import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { StyledForm } from '../WalletCheckout.styled'
import { ThunkDispatch } from 'redux-thunk'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { PaymentContainer, SignupLinkWrapper } from '../../CheckoutPayment/Checkout.styled'

import { tr, useFormValues } from 'mmfintech-commons'
import { actions, configuration } from 'mmfintech-checkout-commons'
import React from 'react'
import { CoreButton, CoreInput } from '../../../components'

export const WalletCheckoutLogin = ({ sessionId, onCancel, onSuccess }) => {
  const { loginError, loginFetching } = useSelector(
    ({ auth: { loginError, loginFetching } }) => ({ loginError, loginFetching }),
    shallowEqual
  )

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const loginValues = useFormValues({
    email: { required: true, validation: 'email' },
    password: { required: true }
  })

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (loginValues.areValid()) {
      void dispatch(actions.auth.login(sessionId, loginValues.prepare(), onSuccess))
    }
  }

  const preparePortalLink = (path: string): string => {
    const host = configuration.readBackendConfig().replace('pay', 'portal')
    return `${host}${path.substring(0, 1) === '/' ? '' : '/'}${path}`
  }

  return (
    <>
      <PaymentContainer data-test='wallet-checkout-login'>
        <StyledForm noValidate onSubmit={handleLogin}>
          <div className='login-title'>{tr('CHECKOUT.LOGIN.SIGN_IN', 'Sign In')}</div>

          <div className='login-form-fields'>
            <CoreInput
              id='email'
              type='email'
              data-test='email'
              label={tr('CHECKOUT.LOGIN.EMAIL', 'Email')}
              {...loginValues.registerInput('email')}
              disabled={loginFetching}
              hideErrorLine
              autoFocus
            />

            <CoreInput
              id='password'
              type='password'
              data-test='password'
              label={tr('CHECKOUT.LOGIN.PASSWORD', 'Password')}
              {...loginValues.registerInput('password')}
              disabled={loginFetching}
              autoComplete='off'
              hideErrorLine
            />
          </div>

          <div className='link-wrap' data-test='forgot-password'>
            <a href={preparePortalLink('forgot-password')} target='_blank' rel='noopener noreferrer'>
              {tr('CHECKOUT.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}
            </a>
          </div>

          <ErrorDisplay error={loginError} />
          <div className='login-buttons'>
            <CoreButton
              type='submit'
              variation='primary'
              fullWidth
              className='mb-1'
              text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Log in')}
              isLoading={loginFetching}
              data-test='button-submit'
            />
            <CoreButton
              type='button'
              variation='tertiary'
              text={tr('CHECKOUT.BUTTONS.BACK', 'Back')}
              disabled={loginFetching}
              onClick={onCancel}
              data-test='button-back'
            />
          </div>
        </StyledForm>
      </PaymentContainer>

      <SignupLinkWrapper>
        {tr('CHECKOUT.LOGIN.NO_ACCOUNT', "Don't have an account?")}
        <a href={preparePortalLink('signup')} data-test='sign-up' target='_blank' rel='noopener noreferrer'>
          <span>
            {tr('CHECKOUT.LOGIN.BUTTON_SIGNUP', 'Sign up')} <span className='arrow'>→</span>
          </span>
        </a>
      </SignupLinkWrapper>
    </>
  )
}

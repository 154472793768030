import { CountryIcon } from '../CountryIcon'
import { CurrencyIcon } from '../CurrencyIcon'
import type { SelectionType } from './selectionTypes'

export function filterOptions(type: SelectionType, options: any[], filterString: string = '') {
  if (options) {
    switch (type) {
      case 'account':
        return options.filter(data => data.name?.toLowerCase().includes(filterString.toLowerCase().trim()))
      case 'country':
        return options.filter(data => data.label?.toLowerCase().includes(filterString.toLowerCase().trim()))
      case 'default':
        return options.filter(data => data.label?.toLowerCase().includes(filterString.toLowerCase().trim()))
      default:
        return options.filter(data => JSON.stringify(data)?.toLowerCase().includes(filterString.toLowerCase().trim()))
    }
  }

  return []
}

export function SelectedIcon({
  type,
  selectedOption,
  isLoading
}: {
  type: SelectionType
  selectedOption: any
  isLoading?: boolean
}) {
  if (selectedOption) {
    switch (type) {
      case 'account':
        return <CurrencyIcon currency={selectedOption.currencyCode} size='32px' circle />
      case 'country':
        return <CountryIcon countryCode={selectedOption.value} size='32px' />
    }
  }
  if (!selectedOption && isLoading && type === 'account') {
    return <CurrencyIcon currency={selectedOption?.currencyCode} size='32px' circle />
  }

  return <></>
}

export function selectedLabel(type: SelectionType, selectedOption: any) {
  if (selectedOption) {
    switch (type) {
      case 'account':
        return selectedOption.name
      case 'country':
        return selectedOption.label
      case 'default':
        return selectedOption.label
      case 'currency':
        return selectedOption.label
    }
  }

  return selectedOption.toString()
}

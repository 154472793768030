import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { tr } from 'mmfintech-commons'

import { PaymentMethodsEmpty, PaymentMethodsWrapper } from './SelectMethod.styled'
import { PaymentMethods } from '../CorePaymentMethods'
import { Spinner } from 'mmfintech-portal-commons'
export const SelectMethod = ({ method, setMethod, countryCode }) => {
  const { checkoutOptions, checkoutOptionsFetching } = useSelector(
    ({ checkout: { checkoutOptions, checkoutOptionsFetching } }) => ({
      checkoutOptions,
      checkoutOptionsFetching
    }),
    shallowEqual
  )

  useEffect(() => {
    if (Array.isArray(checkoutOptions) && checkoutOptions.length === 1) {
      setMethod(checkoutOptions[0])
    }
    // eslint-disable-next-line
  }, [checkoutOptions])

  if (checkoutOptionsFetching) {
    return <Spinner />
  }

  if (Array.isArray(checkoutOptions) && checkoutOptions.length) {
    if (checkoutOptions.length > 0) {
      return (
        <PaymentMethods paymentOptions={checkoutOptions} paymentMethod={method} setSelectedPaymentOption={setMethod} />
      )
    }
  }

  return (
    countryCode && (
      <PaymentMethodsWrapper>
        <PaymentMethodsEmpty>{tr('CHECKOUT.PAYMENT.NO_METHODS', 'No payment methods available')}</PaymentMethodsEmpty>
      </PaymentMethodsWrapper>
    )
  )
}

import styled from 'styled-components'

export const CheckoutContent = styled.div`
  width: 95vw;
  max-width: 75rem;
  margin-bottom: 3rem;

  display: flex;
  flex-flow: column nowrap;
  gap: 2.4rem;
`

export const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  color: black;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.9rem;

  .checkout-icon-wrapper {
    display: flex;
    justify-content: center;
    padding: 2.1rem;
    border-radius: 50%;
    box-shadow: -14px -10px 8px 0 rgba(255, 255, 255, 0.6), 6px 4px 8px -1px rgba(30, 41, 59, 0.1),
      -5px -2px 4px 0 rgba(255, 255, 255, 0.4) inset, 7px 7px 4px 0 rgba(0, 0, 0, 0.03) inset;
    margin-bottom: 2.4rem;
  }

  .heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .timer {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .checkout-button {
    margin-top: 8.8rem;
  }
`

export const PaymentWrapper = styled.div`
  background: #f7f8fa;
  box-shadow: -10px -10px 8px 0 #ffffff66, 10px 10px 8px -1px #1e293b1a, -4px -4px 10px 0 #ffffff99 inset,
    4px 4px 8px 0 #00000008 inset;
  border-radius: 2.4rem;
  padding: 3.2rem;
  width: 100%;
  max-width: 75rem;

  div > .input-wrapper > div {
    border-radius: 16px;
    div > div[id^='react-select'][id$='listbox'] {
      background-color: white;
      border-radius: 16px;
      border: 1px solid #d8d9dc;
      width: 436px;
      left: -20px;
    }
  }

  .payment-fields-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-bottom: 2.4rem;
  }

  div.inner-subtitle {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.4rem;
    font-weight: normal;
  }

  .buttons {
    margin-top: 3.5rem;
    display: flex;
    justify-content: center;
    gap: 0.8rem;
  }
`

import { shallowEqual, useSelector } from 'react-redux'
import { MerchantPaneContainer } from './MerchantPane.styled'

import { findCurrencyPrecision, formatFloat, tr } from 'mmfintech-commons'

type MerchantPaneProps = {
  session: any
  logo?: string
}

export const MerchantPane = ({ session }: MerchantPaneProps) => {
  if (session) {
    const { amount, currency, foreignTransactionId, merchantName } = session || {}
    const { currencies } = useSelector(({ common: { currencies } }) => ({ currencies }), shallowEqual)
    const precision = findCurrencyPrecision(currency, currencies)
    return (
      <MerchantPaneContainer>
        <span className='merchant-pane-pay-to-title'>{`${tr(
          'CHECKOUT.PAYMENT.PAY_TO',
          'Pay to'
        )} ${merchantName}`}</span>
        <div className='merchant-amount-container'>
          <div className='amount-wrapper'>
            <span className='value'>{formatFloat(amount, precision)}</span>
            <span className='currency'>{currency}</span>
          </div>
          <span className='foreign-transaction-text'>{foreignTransactionId}</span>
        </div>
      </MerchantPaneContainer>
    )
  }

  return null
}

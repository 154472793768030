import { isLocalhost } from 'mmfintech-commons'

//initialize(import.meta.env)

const host = typeof window !== 'undefined' ? window.location.host : ''

const isDevelopment = host.indexOf('-dev.') > -1
const isSandbox = host.indexOf('-sandbox.') > -1
export const isProduction = typeof window !== 'undefined' && !isLocalhost && !isSandbox && !isDevelopment

const settings = {
  backendForLocalhost: 'https://pay-dev.thekingdombank.com',
  // backendForLocalhost: 'https://pay-sandbox.thekingdombank.com',
  languages: ['en'],
  customerServiceUrl: 'mailto:team@thekingdombank.com',
  privacyPolicyUrl: 'https://thekingdombank.com/privacy-policy',
  termsAndConditionsUrl: 'https://thekingdombank.com/terms-and-conditions',
  FAQUrl: 'https://thekingdombankhelp.zendesk.com/theming/theme/050fff4c-3294-41e4-95d2-9d801855a5ab'
}

export default settings

import styled from 'styled-components'

export const CryptoPreviewWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 100%;

  font-size: 1.4rem;

  border-radius: 8px;

  .depositPreviewLabel {
    color: black !important;
    text-transform: capitalize !important;
    margin-top: 0.5rem;
    margin-bottom: 0 !important;
  }

  .depositPreviewValue {
    font-weight: 600 !important;
  }
  .code {
    margin: 5rem auto;
  }
  .bordered {
    border: 1px solid #dee3e4;
    border-radius: 15px;
    padding: 15px;
  }

  .control {
    width: 100%;
    margin-bottom: 2rem;

    .label {
      color: black;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: normal;
      line-height: 1.5rem;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    .value {
      color: #000000;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: normal;
      line-height: 2.2rem;
    }

    &.amount {
      .label {
        color: #000000;
      }
      .value {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 2.9rem;
      }
    }
  }

  .amount {
    color: #000000;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.9rem;
  }

  .fee {
    color: #a3b8c2;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: normal;
    line-height: 1.5rem;
  }
  .crypto-address-wrapper {
    width: 100%;
    display: flex;
    gap: 0.8rem;
    flex-direction: column;

    .crypto-address-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      text-align: left;
      color: rgba(2, 6, 23, 1);
    }
    .crypto-address {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;
      border-radius: 1.6rem;
      border: 1px solid rgba(216, 217, 220, 1);
      width: 100%;
    }
  }

  .crypto-preview-buttons-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
`

export const CryptoPreviewTitle = styled.div`
  width: 100%;
  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.9rem;
  text-align: center;

  margin-bottom: 4rem;
`

// @use '@scss/typography' as *;

import styled from 'styled-components'

export const SelectionElementWrapper = styled.div`
  padding: 0.9rem 1.2rem;
  display: flex;
  align-items: center;
  color: #2b313a;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 4.8rem;

  &.selected {
    background-color: #e8eaec;
    cursor: default;
  }

  &:hover:not(.selected) {
    background-color: #f7f8fa;
  }

  .selection-element-caption-label {
    opacity: 50%;
    white-space: nowrap;
  }

  .selection-element-caption-text,
  .selection-element-caption-label {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 4%;
    font-weight: 600;
  }

  .selection-element-content {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.54rem;
    flex: 1;
    white-space: nowrap;
    width: 100%;

    .max-length-text {
      width: 97%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.currency {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
  }

  .selection-element-right-icon {
    position: absolute;
    right: 1.2rem;
    top: 1.8rem;

    svg {
      height: 1.6rem;
      width: 1.6rem;
    }
  }
`

import styled from 'styled-components'

export const WarningMessageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 100%;
  margin-bottom: 2rem;

  padding: 2rem;
  @media (min-width: 762px) {
    padding: 2rem 3rem;
  }

  background: #ffffff;
  border-radius: 1.6rem;
  background-color: #ffffff;
  border: 1px solid rgba(216, 217, 220, 1);
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
`

export const WarningMessageTitle = styled.div`
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;

  display: flex;
  align-items: center;
  justify-items: flex-start;

  color: #000000;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  text-align: left;

  img,
  svg {
    margin-right: 1rem;
  }
`

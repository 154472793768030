export const SuccessIcon = () => (
  <svg width='44' height='43' viewBox='0 0 44 43' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <ellipse cx='21.9999' cy='21.5017' rx='21.6308' ry='20.9753' fill='url(#paint0_linear_8010_23604)' />
    <path
      d='M30.5212 16.2568L20.4359 26.0365C20.0347 26.4256 19.3924 26.4103 19.0102 26.0025L14.7897 21.5007'
      stroke='white'
      stroke-width='1.5'
      stroke-linecap='square'
    />
    <defs>
      <linearGradient
        id='paint0_linear_8010_23604'
        x1='3.39745'
        y1='-2.25349'
        x2='41.0912'
        y2='52.3532'
        gradientUnits='userSpaceOnUse'>
        <stop stop-color='#ABE7CE' />
        <stop offset='0.562838' stop-color='#3DD394' />
      </linearGradient>
    </defs>
  </svg>
)

import styled from 'styled-components'

export const MerchantPaneWrapper = styled.div`
  border-radius: 1rem;
  color: #000000;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  .value {
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .merchant-pane-header-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 1.6rem;
  }

  .merchant-pane-header-content {
    padding: 2.4rem 1.6rem 1.6rem;
    background-color: #ffffff;
    border-radius: 1.6rem;
    border: 1px solid rgba(216, 217, 220, 1);
    gap: 0.8rem;
    display: flex;
    flex-direction: column;
    .merchant-pane-transaction-value {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  .merchant-pane-buttons {
    display: flex;
    gap: 1.6rem;
  }

  .merchant-pane-body-container {
    .subtitle {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: rgba(43, 49, 58, 1);
    }
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-bottom: 1.6rem;

    .merchant-pane-body-wrapper {
      border: 1px solid rgba(231, 236, 239, 1);
      background-color: #ffffff;
      border-radius: 1.2rem;

      .merchant-pane-details-wrapper {
        display: flex;
        flex-direction: column;
        margin: 1.6rem 1.6rem 0 1.6rem;
        padding-bottom: 1.6rem;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(231, 236, 239, 1);
        }

        .merchant-pane-details-title {
          font-size: 14px;
          font-weight: 500;
          line-height: 19.6px;
          text-align: left;
          text-underline-position: from-font;
          color: rgba(43, 49, 58, 0.5);
        }

        .merchant-pane-details-value {
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          text-align: left;
          text-underline-position: from-font;
          color: rgba(43, 49, 58, 1);
        }

        .selection-element-wrapper {
          padding-left: 0;

          &:hover {
            background-color: #ffffff;
            cursor: default;
          }
        }
      }
    }
  }
`

export const MerchantPaneAmountContainer = styled.div`
  background: #f7f8fa;
  box-shadow: -10px -10px 8px 0px #ffffff66, 10px 10px 8.2px -1px #1e293b1a, -4px -4px 10px 0px #ffffff99 inset,
    4px 4px 8px 0px #00000008 inset;

  border-radius: 1rem 1rem 0 0;
  padding: 2.2rem 5rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.4rem;

  .value {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 3.9rem;
    margin-top: 0;
  }
`

export const MerchantPaneNetAmountContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  background: rgba(163, 184, 194, 0.6);
  padding: 0 5rem;
  height: 5rem;

  color: #000000;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  .value {
    color: #000000;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.9rem;
  }
`

export const MerchantPaneInfoContainer = styled.div`
  background: #ffffff;
  border-radius: 0 0 1rem 1rem;
  padding: 2rem 5rem 4rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.9rem;

  .row {
    margin-top: 2rem;
    :first-of-type {
      margin-top: 0;
    }
  }

  .label {
    color: #85a0ad;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .subtitle {
    color: #000000;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 1.5rem;
  }
`

export const MerchantPaneLogoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .logo {
    max-height: 8rem;
    max-width: 20rem;
    margin-right: -2rem;
  }

  .fee {
    color: #678899;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 15px;
  }
`

export const MerchantPaneContainer = styled.div`
  background: #f7f8fa;
  box-shadow: -10px -10px 8px 0px #ffffff66, 10px 10px 8.2px -1px #1e293b1a, -4px -4px 10px 0px #ffffff99 inset,
    4px 4px 8px 0px #00000008 inset;
  border-radius: 2.4rem;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .merchant-pane-pay-to-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
    color: #2b313a;
  }
  .merchant-amount-container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #d8d9dc;
    padding: 2.4rem 1.6rem 1.6rem 1.6rem;
    border-radius: 1.6rem;

    gap: 0.8rem;
    .amount-wrapper {
      display: flex;
      align-items: flex-end;
      gap: 0.4rem;
      .value {
        font-size: 32px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
      }
      .currency {
        font-size: 16px;
        font-weight: 600;
        line-height: 20.8px;
        letter-spacing: -0.01em;
        text-align: left;
      }
    }
    .foreign-transaction-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      text-align: left;
    }
  }
`

import { Dispatch, KeyboardEvent, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import PhoneInput from 'react-phone-input-2'

import cn from 'classnames'

import ClearIcon from '../../assets/icons/clear.svg'
import closedEyeIcon from '../../assets/icons/eye-closed.svg'
import openedEyeIcon from '../../assets/icons/eye-opened.svg'
import { CoreInputWrapper } from './coreInput.style'

interface InputProps {
  className?: string
  error?: any
  LeftIcon?: JSX.Element
  RightIcon?: JSX.Element
  label?: string | ReactNode
  name?: string
  onChange?: any
  hint?: string
  value?: string | number
  required?: boolean
  type?: 'text' | 'password' | 'phone' | 'email' | 'number'
  clearable?: boolean
  setFocus?: Dispatch<SetStateAction<boolean>>
  focused?: boolean
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  disabled?: boolean
  onClick?: () => void
  focusedInput?: (name) => void
  passwordLock?: (boolean) => void
  validation?: (string) => string
  placeholder?: string
  maxLength?: number
  autoComplete?: 'off' | 'on'
  size?: 'small' | 'normal' | 'large'
  onBlur?: (event) => void
  readOnly?: boolean
  blockBlur?: boolean
}

const isIphoneDevice = () => {
  const userAgent = navigator.userAgent
  return /iPhone|iPad|iPod/i.test(userAgent)
}

export const CoreInput = ({
  className = '',
  error,
  LeftIcon,
  RightIcon,
  label,
  name,
  onChange,
  hint,
  value,
  required,
  type = 'text',
  clearable,
  setFocus,
  focused,
  onKeyDown,
  disabled,
  placeholder = '',
  focusedInput,
  passwordLock,
  validation,
  autoComplete = 'off',
  size = 'normal',
  onBlur,
  blockBlur = false,
  ...rest
}: InputProps) => {
  const inputRef = useRef(null)

  const [inputValue, setInputValue] = useState(value)
  const [isFocused, setIsFocused] = useState(false)
  const [inputType, setInputType] = useState(type)
  const [tempDisable, setTempDisable] = useState(isIphoneDevice())

  useEffect(() => {
    if (!value) {
      setInputValue(null)
    }
    if (value) {
      setInputValue(validation ? validation(value) : value)
    }
  }, [value])

  useEffect(() => {
    // moves caret to the end of the input as the default focus sets it at the start
    if (inputRef.current && inputType !== 'email' && inputType !== 'number') {
      inputRef.current.selectionStart = inputRef.current.value.length
      inputRef.current.selectionEnd = inputRef.current.value.length
    }
  }, [inputType])

  let timeoutId = null

  useEffect(() => {
    if (focused) {
      setIsFocused(true)
      inputRef.current && inputRef.current?.focus()
    } else {
      setIsFocused(false)
      focusedInput && focusedInput(undefined)
      inputRef.current && inputRef.current?.blur()
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [focused, blockBlur])

  useEffect(() => {
    if (passwordLock) {
      inputType === 'password' ? passwordLock(true) : passwordLock(false)
    }
  }, [inputType])
  //this prevent IOS (Iphone) to focus and blur input fields on render
  useEffect(() => {
    if (isIphoneDevice())
      setTimeout(() => {
        setTempDisable(false)
      }, 200)
    else {
      setTempDisable(false)
    }
  }, [isIphoneDevice()])

  return (
    <CoreInputWrapper
      onClick={() => {
        setIsFocused(true)
        setFocus && setFocus(true)
        inputRef.current && inputRef.current?.focus()
      }}
      className={cn(className, 'core-input-wrapper', { 'disabled-input': tempDisable || disabled })}>
      <div className={cn('core-input-section', { focused: isFocused, errored: !!error }, size)}>
        {LeftIcon && <div className='core-input-left-icon'>{LeftIcon}</div>}
        <div
          className={cn('core-input-inner-section', {
            'inner-section-phone': type == 'phone',
            'inner-section-final-state': isFocused || !!inputValue
          })}>
          {type == 'phone' ? (
            <PhoneInput
              data-test='phone-input'
              placeholder=''
              onFocus={() => {
                setIsFocused(true)
                focusedInput && focusedInput(name)
                setFocus && setFocus(true)
              }}
              onBlur={e => {
                timeoutId = setTimeout(() => {
                  setIsFocused(false)
                  setFocus && !blockBlur && setFocus(false)
                  onBlur && onBlur(e)
                }, 200)
              }}
              onKeyDown={e => {
                if ((isNaN(Number(e.key)) && e.key !== 'Backspace') || e.key == ' ') {
                  return e.preventDefault()
                }
              }}
              onChange={(value, data: any) => {
                const dialCode = data?.dialCode || ''
                const currentPhoneCode = value.slice(0, dialCode.length)
                if (dialCode !== currentPhoneCode) {
                  setInputValue(dialCode)
                  onChange && onChange(name, dialCode)
                } else {
                  setInputValue(value)
                  onChange && onChange(name, value)
                }
              }}
              value={inputValue + '' || ''}
              disabled={tempDisable || disabled}
              enableSearch
              disableSearchIcon
              searchPlaceholder=''
              inputProps={{ id: name, name, required }}
            />
          ) : (
            <input
              data-test='base-input'
              className={cn('core-input-field', { errored: !!error })}
              onChange={e => {
                setInputValue(e.target.value)
                onChange && onChange(name, e.target.value, e)
              }}
              value={inputValue || ''}
              onFocus={() => {
                if (document.activeElement === inputRef.current) {
                  setIsFocused(true)
                  focusedInput && focusedInput(name)
                  setFocus && setFocus(true)
                }
              }}
              onBlur={e => {
                timeoutId = setTimeout(() => {
                  if (document.activeElement !== inputRef.current) {
                    setIsFocused(false)
                    !blockBlur && setFocus && setFocus(false)
                    onBlur && onBlur(e)
                  }
                }, 200)
              }}
              type={inputType}
              disabled={tempDisable || disabled}
              ref={inputRef}
              required={required}
              name={name}
              onKeyDown={onKeyDown}
              placeholder={isFocused ? placeholder : ''}
              autoComplete={autoComplete}
              {...rest}
            />
          )}
          <span
            className={cn('core-input-moving-label', `${!isFocused && !inputValue ? 'initial-state' : 'final-state'}`)}>
            {label}
          </span>
        </div>
        <div className='core-input-icons-section'>
          {clearable && isFocused && inputValue && (
            <ClearIcon
              onClick={() => {
                setInputValue('')
                inputRef.current && inputRef.current.focus()
                onChange && onChange(name, '')
              }}
            />
          )}
          {RightIcon ||
            (type == 'password' ? (
              inputType == 'password' ? (
                <img
                  src={openedEyeIcon}
                  alt=''
                  className='local'
                  onClick={() => {
                    setInputType('text')
                    inputRef && inputRef.current.focus()
                  }}
                />
              ) : (
                <img
                  src={closedEyeIcon}
                  alt=''
                  className='local'
                  onClick={() => {
                    setInputType('password')
                    inputRef && inputRef.current.focus()
                  }}
                />
              )
            ) : (
              <></>
            ))}
        </div>
      </div>
      {error && (
        <p data-test='core-input-error' className='core-input-error caption mt-1 pl-2'>
          {error}
        </p>
      )}
      {hint && !error && (
        <p data-test='core-input-hint' className='caption-medium core-input-hint mt-1 pl-2'>
          {hint}
        </p>
      )}
    </CoreInputWrapper>
  )
}

// @use '@scss/typography' as *;

import styled from 'styled-components'

export const OtpInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  .opt-input {
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    background-color: #ffffff;
    caret-color: #2b313a;
    font-size: 24px;
    text-align: center;
    border: 1px solid #d8d9dc;
    font-family: #{$font};

    &:focus {
      background-color: #eff1f4;
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      appearance: textfield;
    }
  }
`

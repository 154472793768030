import styled from 'styled-components'

export const CoreSelectWrapper = styled.div`
  position: relative;

  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
  }

  .selected-content .selection-element-wrapper {
    padding: 0.4rem 1.2rem;
    border-radius: 1.6rem;
    border: 1px solid #d8d9dc;
    background-color: #ffffff;

    &:hover {
      border-color: #777777;
    }
  }

  .core-selection-data-container {
    border-radius: 1.6rem;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px 0px #1e293b1f;

    width: 100%;
    max-height: 22.5rem;

    position: absolute;
    z-index: 1000;

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 1.4rem;
      border-top-right-radius: 1.6rem;
      border-bottom-right-radius: 1.6rem;
    }

    &::-webkit-scrollbar-thumb {
      border: 0.4rem solid transparent;
      background-color: #ecedef;
      background-clip: padding-box;
      border-radius: 1rem;
      width: 0.5rem;

      &:hover {
        background-color: #ecedef;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .core-selections-no-options {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.68rem;
      color: #2b313a;
      padding: 1.5rem 0.5rem 0;
      text-align: center;
    }

    .add-account-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.2rem;
      width: 50%;
      min-width: 30rem;
      margin: 0 auto;
    }

    .create-select-component {
      background: #ffffff;
      padding: 12px 16px;
    }
  }

  .inner-dropdown-category-title {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.08rem;
    color: #d8d9dc;
    opacity: 0.6;
    margin-bottom: 0.5rem;
    cursor: pointer;
    padding-left: 4rem;
    border-bottom: 1px solid #d8d9dc;
  }
`

import { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { CoreButton, DurationTimer, PaymentWrapper } from '../../../components'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { OtpResendMessage, PaymentContainer } from '../../CheckoutPayment/Checkout.styled'

import { actions, globalSettings } from 'mmfintech-checkout-commons'
import { checkSingleValue, tr } from 'mmfintech-commons'

import { ThunkDispatch } from 'redux-thunk'
import { TwoFactorTypeEnum } from 'mmfintech-commons-types'
import { OtpInput } from './elements/OtpInput'

const getOptTitle = (twoFactorType: TwoFactorTypeEnum) => {
  switch (twoFactorType) {
    case TwoFactorTypeEnum.SMS:
      return 'SMS verification'

    case TwoFactorTypeEnum.EMAIL:
      return 'Email verification'

    case TwoFactorTypeEnum.TOTP:
      return 'Google Authenticator verification'

    default:
      return 'Email verification'
  }
}
interface WalletCheckoutOtp {
  onSuccess: (response: any) => void
  onCancel?: any
}

export const WalletCheckoutOtp = ({ onSuccess, onCancel }: WalletCheckoutOtp) => {
  const { challenge, resendError, resendFetching, verifyError, verifyFetching } = useSelector(
    ({ auth: { challenge, resendError, resendFetching, verifyError, verifyFetching } }) => ({
      challenge,
      resendError,
      resendFetching,
      verifyError,
      verifyFetching
    }),
    shallowEqual
  )

  const [expired, setExpired] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const [verificationCode, setVerificationCode] = useState('')

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const { challengeId, sentTo, twoFactorType, otpLength } = challenge || {}

  const codeSize = useMemo(() => (otpLength > 0 ? otpLength : globalSettings.otpCodeLength), [otpLength])

  const handleChange = (value: any) => {
    const newValue = checkSingleValue(value, { validation: 'numeric' })
    setVerificationCode(newValue)
  }

  const handleResend = () => {
    void dispatch(
      actions.auth.challengeResend(challengeId, () => {
        setAttempts(0)
        setVerificationCode('')
      })
    )
  }

  useEffect(() => {
    if (verificationCode.length === codeSize && attempts < globalSettings.otpMaxAttempts) {
      setAttempts(attempts + 1)
      void dispatch(actions.auth.challengeVerify(challengeId, verificationCode, onSuccess))
    }
    // eslint-disable-next-line
  }, [verificationCode])

  useEffect(() => {
    return () => {
      dispatch(actions.auth.challengeCleanup())
    }
  }, [dispatch])

  return (
    <PaymentWrapper>
      <PaymentContainer className='vertically-centered' data-test='wallet-checkout-otp'>
        <div className='otp-title'>{getOptTitle(twoFactorType)}</div>
        <div className='otp-subtitle'>
          {twoFactorType === TwoFactorTypeEnum.TOTP
            ? tr('CHECKOUT.OTP.ENTER_CODE', 'Please enter the verification code')
            : tr('CHECKOUT.OTP.ENTER_CODE_TO', "Please enter the verification code that we've sent to")}
          : {twoFactorType !== TwoFactorTypeEnum.TOTP && <span>{sentTo}</span>}
        </div>

        <ErrorDisplay error={verifyError} />

        {resendFetching ? null : resendError ? (
          <>
            <ErrorDisplay error={resendError} />

            {onCancel && (
              <CoreButton
                type='button'
                variation='primary'
                fullWidth
                text={tr('CHECKOUT.BUTTONS.CANCEL', 'Cancel')}
                onClick={onCancel}
                data-test='button-cancel'
              />
            )}
          </>
        ) : (
          <>
            <div className='text-center'>
              <OtpInput
                codeLength={codeSize}
                onChange={handleChange}
                disabled={expired || attempts >= globalSettings.otpMaxAttempts}
                value={verificationCode}
              />
            </div>

            {twoFactorType !== TwoFactorTypeEnum.TOTP && (
              <OtpResendMessage>
                {attempts < globalSettings.otpMaxAttempts ? (
                  <>
                    {tr('CHECKOUT.OTP.HAVE_NOT_RECEIVED', "Haven't received it?")}
                    <span onClick={handleResend}>{tr('CHECKOUT.OTP.RESEND_CODE', 'Re-send code')}</span>
                  </>
                ) : (
                  tr('CHECKOUT.OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
                )}
              </OtpResendMessage>
            )}

            <div className='mt-3'>
              {onCancel && (
                <CoreButton
                  type='button'
                  variation='primary'
                  text={tr('CHECKOUT.BUTTONS.CANCEL', 'Cancel')}
                  fullWidth
                  disabled={verifyFetching}
                  onClick={onCancel}
                  data-test='button-cancel'
                />
              )}
            </div>
          </>
        )}

        {attempts < globalSettings.otpMaxAttempts && !resendFetching && <DurationTimer setCustomExpired={setExpired} />}
      </PaymentContainer>
    </PaymentWrapper>
  )
}

import { useDispatch } from 'react-redux'

import { ThunkDispatch } from 'redux-thunk'

import { tr } from 'mmfintech-commons'
import { actions } from 'mmfintech-checkout-commons'

import {
  BaseInstructionsResult,
  FawryPayInstructionsResult,
  FlutterwaveMobileAppInstructionsResult,
  PaymentMethodEnum,
  PaymentSessionData
} from 'mmfintech-commons-types'
import { BankwirePreviewWrapper } from '../PreviewBankwire/PreviewBankwire.styled'
import { CoreButton } from '../../../components/CoreButton'

interface PreviewInstructionsProps {
  result: BaseInstructionsResult
  session?: PaymentSessionData
  sessionId?: string
}

export const PreviewInstructions = ({ result, session, sessionId }: PreviewInstructionsProps) => {
  const { successUrl } = session || {}
  const { paymentMethod } = result || {}

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleRedirect = () => {
    void dispatch(actions.common.eventTrack(sessionId, 'redirected_back_to_merchant'))
    window.location.href = successUrl
  }

  const RedirectButton = () => (
    <>
      {successUrl ? (
        <div className='buttons'>
          <CoreButton
            type='button'
            variation='primary'
            onClick={handleRedirect}
            text={tr('CHECKOUT.BUTTONS.CLOSE', 'Close')}
            data-test='button-back'
          />
        </div>
      ) : null}
    </>
  )

  if (paymentMethod === PaymentMethodEnum.FAWRY_PAY) {
    const { instructions } = (result as FawryPayInstructionsResult) || {}
    return (
      <>
        <BankwirePreviewWrapper>{instructions}</BankwirePreviewWrapper>
        <RedirectButton />
      </>
    )
  }

  if (paymentMethod === PaymentMethodEnum.M_PESA) {
    const { phoneNumber } = (result as FlutterwaveMobileAppInstructionsResult) || {}
    return (
      <>
        <BankwirePreviewWrapper>
          {tr(
            'CHECKOUT.INSTRUCTIONS.MPESA',
            'You will receive a prompt on mobile number {{phone_number}} to enter your MPESA service PIN to authorize your payment request.',
            { phone_number: phoneNumber }
          )}
        </BankwirePreviewWrapper>

        <RedirectButton />
      </>
    )
  }

  if (paymentMethod === PaymentMethodEnum.MOBILE_MONEY) {
    const { phoneNumber } = (result as FlutterwaveMobileAppInstructionsResult) || {}
    return (
      <>
        <BankwirePreviewWrapper>
          {tr(
            'CHECKOUT.INSTRUCTIONS.MOBILE_MONEY',
            'You will receive a prompt on mobile number {{phone_number}} to enter your Mobile Money service PIN to authorize your payment request.',
            { phone_number: phoneNumber }
          )}
        </BankwirePreviewWrapper>

        <RedirectButton />
      </>
    )
  }

  return null
}

import styled from 'styled-components'

export const CountryIconWrapper = styled.span<{ size: string }>`
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: 1px solid #eee;
  border-radius: 8px;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  &.circle {
    border-radius: 50%;
    & > img {
      border-radius: 50%;
    }
  }

  &.borderless {
    border: none;
    border-radius: 0;
    & > img {
      border: none;
      border-radius: 0;
    }
  }
`

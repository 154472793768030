import { ReactNode, useContext } from 'react'

import { ThemeContext } from 'styled-components'
import { CheckoutHeader, CheckoutWrapper } from './CheckoutContainer.styled'
import { SupportBlock } from '../../components'

type CheckoutContainerProps = {
  children: ReactNode
}

export const CheckoutContainer = ({ children }: CheckoutContainerProps) => {
  const themeContext = useContext(ThemeContext)

  return (
    <CheckoutWrapper>
      <CheckoutHeader>
        <span className='logo'>
          <img src={themeContext.brandLogo} alt='Logo' />
        </span>
      </CheckoutHeader>
      {children}
      <SupportBlock />
    </CheckoutWrapper>
  )
}

export const ClipboardIcon = () => (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#clip0_7609_10973)'>
      <rect x='7' y='7.18945' width='14' height='14' rx='4' stroke='#D8D9DC' stroke-width='1.5' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M17.3097 5.18945C16.5547 3.56544 14.9089 2.43945 13 2.43945H7C4.37665 2.43945 2.25 4.5661 2.25 7.18945V13.1895C2.25 15.0983 3.37598 16.7442 5 17.4991V15.7514C4.23912 15.1565 3.75 14.2301 3.75 13.1895V7.18945C3.75 5.39453 5.20507 3.93945 7 3.93945H13C14.0407 3.93945 14.9671 4.42857 15.5619 5.18945H17.3097Z'
        fill='#D8D9DC'
      />
    </g>
    <defs>
      <clipPath id='clip0_7609_10973'>
        <rect width='24' height='24' fill='white' transform='translate(0 0.189453)' />
      </clipPath>
    </defs>
  </svg>
)

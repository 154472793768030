import styled from 'styled-components'

export const CoreInputWrapper = styled.div`
  .core-input-hint {
    color: #2b313a;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.32rem;
  }

  .core-input-error {
    color: #ef4444;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.32rem;
  }

  &.disabled-input {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;

    *:hover,
    *.focused {
      background-color: #ffffff;
      border-color: #d8d9dc;
    }
  }

  input {
    font-family: 'Inter', sans-serif;
  }
  .core-input-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 4.8rem;
    background-color: #ffffff;
    border-radius: 1.6rem;
    font-weight: 700;
    white-space: nowrap;
    padding: 0.8rem 1.1rem;
    border: 1px solid #d8d9dc;

    input:-webkit-autofill::first-line,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text;
    }

    &:hover {
      border-color: #777777;
    }

    &.focused {
      background-color: #eff1f4;
      border-color: #4d5055;
    }

    &.errored {
      border: 1px solid #ef4444;
    }

    &:has(.form-control.open) {
      background-color: #eff1f4;
    }

    &.small {
      height: 3.6rem;
    }

    &.large {
      height: 5.6rem;
    }

    .form-control {
      background-color: inherit;
      border: none;
      padding: 0;
      width: 100%;
    }

    svg {
      min-height: 1.6rem;
      min-width: 1.6rem;
    }

    .core-input-left-icon {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 3.2rem;
      min-height: 3.2rem;
    }

    .core-input-inner-section {
      width: 100%;
      padding-left: 1rem;
      position: relative;
      // overflow: hidden;

      &.inner-section-phone {
        margin-left: 5rem;
      }

      &.inner-section-final-state {
        .core-input-field {
          margin-top: 1.4rem;
        }

        .react-tel-input input {
          height: 2.4rem;
          margin-top: 0.5rem !important;
        }

        .react-tel-input .flag-dropdown .selected-flag .flag .arrow {
          top: 0px;
          left: 2rem;
        }
      }

      .react-tel-input {
        height: 2rem;
        position: initial;

        font-family: #{$font};

        & > * {
          height: 2rem;
        }

        input {
          font-family: inherit;
          font-weight: 500;
          font-size: 1.6rem;
          caret-color: #2b313a;
        }

        ul.country-list {
          margin-top: 1.6rem;
          background-color: white;
          box-shadow: 0px 2px 8px 0px #1e293b1f;
          padding-left: 1rem;
          left: -1rem;
          width: 35rem;
          border-radius: 1.6rem;

          &::-webkit-scrollbar {
            width: 0.6rem;
            border-top-right-radius: 1.6rem;
            border-bottom-right-radius: 1.6rem;
          }

          &::-webkit-scrollbar-thumb {
            border: none;
            background-color: white;
            background-clip: padding-box;
            border-radius: 1rem;
            width: 0.5rem;

            &:hover {
              background-color: white;
            }
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          .search {
            z-index: 10;
            background-color: white;

            input {
              border-radius: 1.2rem;
              width: 95%;
              margin-left: 0;
            }
          }

          .country {
            padding: 0.9rem;

            &:hover,
            &.highlight {
              background-color: rgba(#ffffff, 0.5);
            }
          }
        }

        .flag-dropdown {
          left: -5rem;
          border: none;

          &,
          & > div {
            background-color: inherit;
          }

          .flag {
            transform: scale(1.4);
          }

          .arrow {
            background-image: url('@images/icons/chevron-down-black.svg');
            background-size: 1rem 1rem;
            background-position: center;
            top: 0;
            left: 2rem;
            margin-top: 2px;
            width: 1rem;
            height: 1rem;
            border: none;
          }
        }
      }
    }

    .core-input-icons-section {
      display: flex;
      max-width: 8rem;
      flex-direction: row;
      justify-content: flex-end;
      gap: 1.6rem;

      > * {
        cursor: pointer;
      }
    }
  }

  .core-input-moving-label {
    font-size: 1.6rem;
    line-height: 2.08rem;
    font-weight: 700;
    position: absolute;
    left: 1.2rem;
    top: 2px;
    line-height: 140%;
    transition: all 300ms;
    pointer-events: none;
    color: rgba(#2b313a, 0.5);

    &.initial-state {
      font-size: 1.4rem;
    }

    &.final-state,
    input:-webkit-autofill + & {
      font-size: 1rem;
    }
  }

  .react-tel-input + .core-input-moving-label {
    &.final-state {
      top: -0.8rem;
    }
  }
  .core-input-field {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.08rem;
    border: none;
    width: 100%;
    color: #2b313a;

    font-size: 1.6rem;
    caret-color: #2b313a;

    background-color: transparent;
    transition: all 300ms;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(#2b313a, 0.5);
    }

    &.errored {
      color: #ef4444;
    }

    &:-webkit-autofill {
      margin-top: 1.4rem;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      appearance: textfield;
    }
  }
`

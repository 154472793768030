import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import toast from 'react-hot-toast'

import { actions, configuration, usePolling } from 'mmfintech-checkout-commons'
import {
  copyTextToClipboard,
  findCurrencyPrecision,
  formatFloat,
  formatMoney,
  getCurrencyImageUrl,
  tr
} from 'mmfintech-commons'

import { QRCodeSVG } from 'qrcode.react'
import { CryptoPreviewTitle, CryptoPreviewWrapper } from './PreviewCrypto.styled'
import { CoreButton, PaymentWrapper, WarningMessage } from '../../../components'

import { ThunkDispatch } from 'redux-thunk'

import { ClipboardIcon } from '../../../icons/ClipboardIcon'

type PreviewCryptoProps = {
  sessionId?: string
  data?: any
  onBack?: () => void
  onClose?: () => void
}

const STATUS_POLLING_TIMEOUT = 30000 // 30 seconds

export const PreviewCrypto = ({ sessionId, data, onClose, onBack }: PreviewCryptoProps) => {
  const { currencies } = useSelector(({ common: { currencies } }) => ({ currencies }), shallowEqual)

  const { address, currency, tag, processingAmount } = data || {}

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()
  const polling = usePolling(() => reloadSessionStatus(), 'status.reload')

  const handleStatusFetchSuccess = (response: any): void => {
    const { status } = response?.data || {}

    switch (status) {
      case 'PROCESSED':
      case 'CONFIRMED':
        history.push('/success')
        break

      case 'FAILED':
      case 'CANCELLED':
        history.push('/fail')
        break

      default:
        polling.start(STATUS_POLLING_TIMEOUT)
    }
  }

  const handleStatusFetchFail = () => {
    polling.start(STATUS_POLLING_TIMEOUT)
  }

  const reloadSessionStatus = () => {
    void dispatch(actions.checkout.fetchSession(sessionId, handleStatusFetchSuccess, handleStatusFetchFail))
  }

  useEffect(() => {
    if (sessionId) {
      polling.start(STATUS_POLLING_TIMEOUT)
    }
  }, [])

  return (
    <>
      <PaymentWrapper>
        <CryptoPreviewWrapper>
          <CryptoPreviewTitle>{tr('CHECKOUT.CRYPTO_DETAILS.TITLE', 'Details')}</CryptoPreviewTitle>

          <div className='text-center code bordered'>
            <QRCodeSVG
              value={address}
              size={220}
              imageSettings={{
                src: `${configuration.readBackendConfig()}${getCurrencyImageUrl(currency)}`,
                width: 40,
                height: 40,
                excavate: true
              }}
            />
          </div>

          <div className='crypto-address-wrapper'>
            <span className='crypto-preview-title'>{tr('CHECKOUT.CRYPTO_DETAILS.ADDRESS', 'Deposit address')}</span>
            <div className='crypto-address'>
              <span className='crypto-address-text'>{address}</span>
              <CoreButton
                className='crypto-address-copy-button'
                CollapsedIcon={<ClipboardIcon />}
                collapsed
                onClick={() => {
                  copyTextToClipboard(address, () => {
                    toast.remove()
                    toast.success(
                      tr('CHECKOUT.CRYPTO_DETAILS.ADDRESS_COPY_SUCCESS', 'The address is copied to clipboard')
                    )
                  })
                }}
              />
            </div>

            {tag && (
              <>
                <span className='crypto-preview-title'>{tr('CHECKOUT.CRYPTO_DETAILS.TAG', 'Tag')}</span>
                <div className='crypto-address'>
                  <span className='crypto-address-text'>{tag}</span>
                  <CoreButton
                    className='crypto-address-copy-button'
                    CollapsedIcon={<ClipboardIcon />}
                    collapsed
                    onClick={() => {
                      copyTextToClipboard(tag, () => {
                        toast.remove()
                        toast.success(tr('CHECKOUT.CRYPTO_DETAILS.TAG_COPY_SUCCESS', 'The tag is copied to clipboard'))
                      })
                    }}
                  />
                </div>
              </>
            )}
            {processingAmount && (
              <>
                <span className='crypto-preview-title'>{tr('CHECKOUT.CRYPTO_DETAILS.AMOUNT', 'Amount')}</span>
                <div className='crypto-address'>
                  <span className='crypto-address-text'>
                    <span className='currency'>{formatMoney(processingAmount, currency, currencies)}</span>
                  </span>
                  <CoreButton
                    className='crypto-address-copy-button'
                    CollapsedIcon={<ClipboardIcon />}
                    collapsed
                    onClick={() => {
                      copyTextToClipboard(
                        formatFloat(processingAmount, findCurrencyPrecision(currency, currencies)),
                        () => {
                          toast.remove()
                          toast.success(
                            tr('CHECKOUT.CRYPTO_DETAILS.AMOUNT_COPY_SUCCESS', 'The amount is copied to clipboard')
                          )
                        }
                      )
                    }}
                  />
                </div>
              </>
            )}
          </div>

          <div className='mb-2' />

          <WarningMessage currency={currency} />
          <div className='crypto-preview-buttons-wrapper'>
            {typeof onClose === 'function' && (
              <CoreButton
                type='button'
                variation='primary'
                text={tr('CHECKOUT.BUTTONS.CLOSE', 'Close')}
                onClick={onClose}
                fullWidth
                data-test='button-close'
              />
            )}
            {typeof onBack === 'function' && (
              <CoreButton
                type='button'
                variation='tertiary'
                text={tr('CHECKOUT.BUTTONS.BACK', 'Back')}
                onClick={onBack}
                fullWidth
                data-test='button-back'
              />
            )}
          </div>
        </CryptoPreviewWrapper>
      </PaymentWrapper>
    </>
  )
}

import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { StyledForm } from '../WalletCheckout.styled'
import { CoreButton, CoreSelect } from '../../../components'
import { PaymentContainer } from '../../CheckoutPayment/Checkout.styled'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { tr } from 'mmfintech-commons'
import { actions } from 'mmfintech-checkout-commons'
import { ThunkDispatch } from 'redux-thunk'
import { PaymentMethodEnum } from 'mmfintech-commons-types'

export const WalletCheckoutAccount = ({ selectedAccount, setSelectedAccount, onSuccess, onCancel }) => {
  const { session, walletAccounts, walletAccountsError, walletPreviewError, walletPreviewFetching } = useSelector(
    ({
      checkout: {
        session,
        walletAccounts,
        walletAccountsError,
        walletAccountsFetching,
        walletPreviewError,
        walletPreviewFetching
      }
    }) => ({
      session,
      walletAccounts,
      walletAccountsError,
      walletAccountsFetching,
      walletPreviewError,
      walletPreviewFetching
    }),
    shallowEqual
  )

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (selectedAccount) {
      void dispatch(
        actions.checkout.payWithWalletPreview(selectedAccount.id, PaymentMethodEnum.KINGDOM_WALLET, onSuccess)
      )
    }
  }

  useEffect(() => {
    if (session) {
      const { currency } = session
      void dispatch(actions.checkout.payWithWalletAccounts(currency, null))
    }
    // eslint-disable-next-line
  }, [session])

  return (
    <PaymentContainer>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <div className='account-title'>{tr('CHECKOUT.WALLET_PAYMENT.TITLE', 'Pay With Wallet')}</div>

        <CoreSelect
          className='mb-3'
          options={walletAccounts}
          label={tr('CHECKOUT.WALLET_PAYMENT.ACCOUNT', 'Account')}
          onChange={(_: string, value: any) => setSelectedAccount(value)}
          value={selectedAccount}
          type='account'
        />

        <ErrorDisplay error={[walletAccountsError, walletPreviewError]} />
        <div className='wallet-checkout-accounts'>
          <CoreButton
            type='submit'
            variation='primary'
            className='mb-1'
            text={tr('CHECKOUT.BUTTONS.CONTINUE', 'Continue')}
            fullWidth
            isLoading={walletPreviewFetching}
            disabled={!selectedAccount}
            data-test='button-submit'
          />
          <CoreButton
            type='button'
            variation='tertiary'
            text={tr('CHECKOUT.BUTTONS.BACK', 'Back')}
            disabled={walletPreviewFetching}
            onClick={onCancel}
            data-test='button-back'
          />
        </div>
      </StyledForm>
    </PaymentContainer>
  )
}

import cn from 'classnames'

import { CurrencyIconWrapper } from './CurrencyIcon.styled'
import { configuration } from 'mmfintech-checkout-commons'
import { getCurrencyImageUrl } from 'mmfintech-commons'

type CurrencyIconProps = {
  currency: string
  size?: string
  circle?: boolean
  className?: string
}

export const CurrencyIcon = ({ currency, size = '3rem', circle = false, className = '' }: CurrencyIconProps) => (
  <CurrencyIconWrapper
    size={size}
    className={cn(className, {
      circle,
      [currency?.replace(/\./g, '-')]: true
    })}>
    <img src={`${configuration.readBackendConfig()}${getCurrencyImageUrl(currency)}`} alt='' />
  </CurrencyIconWrapper>
)

interface IClassConfig {
  buttonClass: string
  loadButtonClass: string
  collapsedClass?: string
}

type TButtonConfig = {
  [key in buttonValidation]: IClassConfig
}

export type buttonValidation = 'primary' | 'tertiary'

const buttonConfiguration: TButtonConfig = {
  primary: {
    buttonClass: 'large-primary-button',
    loadButtonClass: 'large-primary-button loading',
    collapsedClass: 'large-primary-button collapsed'
  },
  tertiary: {
    buttonClass: 'large-tertiary-button',
    loadButtonClass: 'large-tertiary-button loading',
    collapsedClass: 'large-tertiary-button collapsed'
  }
}

export default buttonConfiguration

// noinspection SpellCheckingInspection

import styled from 'styled-components'

export const PaymentMethodsWrapper = styled.div`
  .row {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.9rem;

    margin-top: 2rem;
    :first-of-type {
      margin-top: 0;
    }

    .label {
      color: #85a0ad;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }
  }
`

export const PaymentMethodsEmpty = styled.div`
  color: #808080;
  font-style: normal;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
`

export const PaymentMethodsTitle = styled.div`
  color: #000000;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;

  margin-bottom: 2rem;
`

export const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  &.use-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
`

export const PaymentMethodButton = styled.div`
  cursor: pointer;
  position: relative;

  width: 100%;
  max-width: 30%;
  padding: 0;

  .use-grid & {
    max-width: unset;
  }

  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  color: #000000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;
  text-align: center;

  &.selected {
    color: #000000;
  }

  :hover {
    color: #000000;
  }
`

export const PaymentMethodChecked = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(7px, -7px);
`

export const MethodPaneWrapper = styled.div`
  background: rgba(49, 131, 255, 0.05);
  border: 1px solid rgba(0, 97, 232, 0.3);
  border-radius: 8px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 2rem;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;

  .icon {
    width: 3.5rem;
    height: 3.5rem;
    background-size: contain;
    border: none;
  }
`

import { toast } from 'react-hot-toast'

import { QRCodeSVG } from 'qrcode.react'
import { QrCodeWrapper } from './PreviewPix.styled'

import { copyTextToClipboard, tr } from 'mmfintech-commons'

import PixLogo from '../../../assets/icons/pix-logo.svg'
import { CoreButton } from '../../../components/CoreButton'

export const PreviewPix = ({ value }) => (
  <QrCodeWrapper>
    <p className='text-center'>
      <img src={PixLogo} alt='' />
    </p>

    <p className='text-center'>
      {tr(
        'CHECKOUT.RESULT.PIX_QR_CODE',
        'Open the app with your registered PIX key, choose Pay with Pix and scan the QR Code or copy and paste the code.'
      )}
    </p>

    <p className='text-center code'>
      <QRCodeSVG value={value} size={256} />
    </p>

    <p className='text-center'>
      <CoreButton
        type='button'
        variation='primary'
        text={tr('CHECKOUT.RESULT.COPY_CODE', 'Copy code')}
        onClick={() => {
          copyTextToClipboard(value, () => {
            toast.remove()
            toast.success(tr('CHECKOUT.RESULT.COPY_SUCCESS', 'Code is copied to clipboard').toString())
          })
        }}
      />
    </p>
  </QrCodeWrapper>
)

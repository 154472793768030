import styled from 'styled-components'

export const SupportPaneWrapper = styled.div`
  width: 100%;
  max-width: 440px;
  height: fit-content;
  box-shadow: -10px -10px 8px 0px #ffffff99, 10px 10px 8.2px -1px #1e293b1a, -4px -4px 10px 0px #ffffff99 inset,
    4px 4px 8px 0px #00000008 inset;
  background-color: #f7f8fa;
  border-radius: 2.4rem;
  padding: 3.2rem 2.4rem 1.6rem 2.4rem;
  margin-bottom: 6rem;

  .support-panel-right-side-title {
    opacity: 0.5;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.68rem;
    padding-bottom: 1.6rem;
  }

  .support-panel-right-side-support-wrapper {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.54rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.2rem;
    padding: 1.2rem 0;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    color: #020617;
    a {
      text-decoration: none;
    }
  }

  .support-panel-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8rem;
    height: 4.8rem;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: -6px -6px 8px 0px #ffffff99, 3px 4px 8.2px -1px #1e293b1a, -2px -2px 4px 0px #ffffff99 inset,
      2px 2px 4px 0px #00000008 inset;
    &:hover {
      background-color: #f7f8fa;
      box-shadow: -3px -4px 8px 0px #ffffff99, 3px 4px 8.2px -1px #1e293b1a, -4px -4px 30px 0px #ffffff80 inset,
        3px 4px 8px 0px #00000008 inset;
    }
  }

  @media (max-width: 1400px) {
    max-width: 75rem;
  }
`

import styled from 'styled-components'

export const StyledForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  .input-wrapper,
  .button {
    width: 100%;
  }

  .login-title {
    color: rgba(43, 49, 58, 1);
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .account-title {
    margin-bottom: 3rem;
  }

  .login-form-fields {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .login-buttons {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
  }

  .wallet-checkout-accounts {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
  }
`

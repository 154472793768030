import { useDispatch } from 'react-redux'

import { CoreButton, LabelValue } from '../../../components'
import { BankwirePreviewTitle, BankwirePreviewWrapper, WarningMessageWrapper } from './PreviewBankwire.styled'

import { actions } from 'mmfintech-checkout-commons'
import { isNotEmptyString, tr } from 'mmfintech-commons'

import { ThunkDispatch } from 'redux-thunk'

export const PreviewBankwire = ({ result, session, sessionId }) => {
  const { successUrl } = session || {}

  const {
    recipientAddress,
    bankName,
    bankAddress,
    iban,
    bicSwift,
    bankCode,
    branchCode,
    accountNumber,
    accountOwner,
    bankCountryCode,
    accountType,
    // documentId,
    // documentType,
    reference
  } = result

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleRedirect = () => {
    void dispatch(actions.common.eventTrack(sessionId, 'redirected_back_to_merchant'))
    window.location.href = successUrl
  }

  // noinspection SpellCheckingInspection
  const formatAccountNumber = (): string | null =>
    accountNumber ? `${accountType === 'BANKGIRO' ? 'BG ' : ''}${accountNumber}` : null

  const getCountryCode = () => (bankCountryCode ? bankCountryCode : iban && iban.substring(0, 2) === 'GB' ? 'GBR' : '')

  return (
    <BankwirePreviewWrapper>
      <BankwirePreviewTitle data-test='bank-transfer-details'>
        {tr('FRONTEND.DEPOSIT.PREVIEW.TITLE', 'Bank Transfer Details')}
      </BankwirePreviewTitle>

      <LabelValue label={tr('FRONTEND.DEPOSIT.PREVIEW.BANK_NAME', 'Bank Name')} value={bankName} dataTest='bank-name' />
      <LabelValue
        label={tr('FRONTEND.DEPOSIT.PREVIEW.BANK_ADDRESS', 'Bank Address')}
        value={bankAddress}
        dataTest='bank-address'
      />
      <LabelValue label={tr('FRONTEND.DEPOSIT.PREVIEW.IBAN', 'IBAN')} value={iban} dataTest='iban' />
      <LabelValue label={tr('FRONTEND.DEPOSIT.PREVIEW.BIC_SWIFT', 'BIC/SWIFT')} value={bicSwift} dataTest='bic-swift' />
      <LabelValue
        label={tr(
          `FRONTEND.DEPOSIT.PREVIEW.BANK_NUMBER_${getCountryCode()}`,
          tr('FRONTEND.DEPOSIT.PREVIEW.BANK_NUMBER', 'Bank Number')
        )}
        value={bankCode}
        dataTest='bank-number'
      />
      <LabelValue
        label={tr('FRONTEND.DEPOSIT.PREVIEW.BRANCH_CODE', 'Branch code')}
        value={branchCode}
        dataTest='branch-code'
      />
      <LabelValue
        label={tr('FRONTEND.DEPOSIT.PREVIEW.ACCOUNT_NUMBER', 'Account Number')}
        value={formatAccountNumber()}
        dataTest='account-number'
      />
      <LabelValue
        label={tr('FRONTEND.DEPOSIT.PREVIEW.ACCOUNT_OWNER', 'Account Owner')}
        value={accountOwner}
        dataTest='account-owner'
      />
      <LabelValue
        label={tr('FRONTEND.DEPOSIT.PREVIEW.OWNER_ADDRESS', 'Owner Address')}
        value={recipientAddress}
        dataTest='recipient-address'
      />
      <LabelValue
        label={tr('FRONTEND.DEPOSIT.PREVIEW.REFERENCE', 'Reference')}
        value={reference}
        dataTest='reference'
      />

      {isNotEmptyString(reference) && (
        <WarningMessageWrapper>
          {tr(
            'FRONTEND.DEPOSIT.PREVIEW.WARNING1',
            'Please pay attention to enter your unique reference to your transaction. If the reference is blank or wrong, the transaction will be declined and you will need to enter the correct reference and try again.'
          )}
        </WarningMessageWrapper>
      )}

      {successUrl ? (
        <CoreButton
          type='button'
          variation='primary'
          onClick={handleRedirect}
          text={tr('CHECKOUT.BUTTONS.CLOSE', 'Close')}
          data-test='button-back'
        />
      ) : null}
    </BankwirePreviewWrapper>
  )
}
